import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import {
  Button, Select, ErrorMessage, Input,
} from 'toc-styled-components';
import styled from 'styled-components';
import ImageUpload from '../../components/Form/components/ImageUpload/ImageUpload';
import api from '../api';

const StyledH3 = styled.h3`
  display: inline-block;
  justify-content: space-between;
  > div {
    font-size: initial;
    font-weight: normal;
  }
`;

function TruckOffloadingForm() {
  const { t } = useTranslation();
  const {
    register, setValue, formState: { errors },
  } = useFormContext();

  const invalidLicensePlate = t('invalidLicensePlate');

  const validateLicensePlate = (value: string) => {
    const regex = /^[A-Z][0-9]{3}[A-Z]{3}$/;
    return regex.test(value) || invalidLicensePlate;
  };

  const autofillLatest = () => {
    api.get(['truckoffloading', 'latest']).then((response) => {
      const autoFillFields = ['trash_origin', 'destination'];
      if (response.data) {
        autoFillFields.forEach((field) => {
          if (response.data[field]) {
            setValue(field, response.data[field]);
          }
        });
      }
    });
  };

  const [tareWeight, setTareWeight] = useState(0);
  const [grossWeight, setGrossWeight] = useState(0);
  const [netWeight, setNetWeight] = useState(0);
  const [weightType, setWeightType] = useState('gross&tare');

  useEffect(() => {
    if (weightType === 'gross&tare') {
      const newNetWeight = grossWeight - tareWeight;
      setNetWeight(newNetWeight);
      setValue('net_weight', newNetWeight);
    } else {
      setGrossWeight(0);
      setValue('gross_weight', undefined);

      setTareWeight(0);
      setValue('tare_weight', undefined);
    }
  }, [tareWeight, grossWeight, weightType]);

  const handleTareWeightChange = (event: any) => {
    const newTareWeight = parseFloat(event.target.value);
    setTareWeight(newTareWeight);
  };

  const handleGrossWeightChange = (event: any) => {
    const newGrossWeight = parseFloat(event.target.value);
    setGrossWeight(newGrossWeight);
  };

  const handleWeightTypeChange = (event: any) => {
    setWeightType(event.target.value);
  };

  return (
    <>
      <StyledH3>Offloading Information</StyledH3>
      <Button style={{ float: 'right' }} title={t('autofillLatestTitle')} onClick={autofillLatest}>
        {t('autofillLatest')}
      </Button>
      <Input
        type="string"
        label={t('licensePlate')}
        required
        error={!!errors.license_plate}
        {...register('license_plate', {
          required: { value: true, message: t('fieldRequired') },
          validate: validateLicensePlate,
        })}
      />
      <ErrorMessage message={errors.license_plate?.message} />
      <Select
        {...register('trash_origin', { required: true })}
        required
        label={t('trashOrigin')}
        error={!!errors.trash_origin}
      >
        <option value="BiosferaGT">BiosferaGT</option>
        <option value="Rio Interceptor 1">Rio Interceptor 1</option>
        <option value="Rio Interceptor 2">Rio Interceptor 2</option>
        <option value="Interceptor 021">Interceptor 021</option>
        <option value="Legacy Trash El Quetzalito">Legacy Trash El Quetzalito</option>
      </Select>
      <Select
        {...register('destination', { required: true })}
        required
        label={t('destinationTruck')}
        error={!!errors.destination}
      >
        <option value="BiosferaGT">BiosferaGT</option>
        <option value="Zona 3">Zona 3</option>
        <option value="Proverde">Proverde</option>
        <option value="Morazan">Morazan</option>
        <option value="Other">Other</option>
      </Select>
      <div>
        <p>Weight Type</p>
        <div>
          <input
            type="radio"
            value="gross&tare"
            checked={weightType === 'gross&tare'}
            onChange={handleWeightTypeChange}
            style={{ width: '10%' }}
          />
          <span>{t('grossAndTare')}</span>
        </div>
        <div style={{ padding: '0.5em 0' }}>
          <input
            type="radio"
            value="net"
            checked={weightType === 'net'}
            onChange={handleWeightTypeChange}
            style={{ width: '10%' }}
          />
          <span>{t('net')}</span>
        </div>
      </div>
      <Input
        type="number"
        label={t('grossWeight')}
        required={weightType === 'gross&tare'}
        error={!!errors.gross_weight}
        readOnly={weightType === 'net'}
        value={grossWeight}
        style={{
          background: weightType === 'net' ? '#F5F5F5' : 'inherit',
          color: weightType === 'net' ? '#E9E9E9' : 'inherit',
        }}
        {...register('gross_weight', {
          value: grossWeight,
          required: { value: weightType === 'gross&tare', message: t('fieldRequired') },
          onChange: handleGrossWeightChange,
        })}
      />
      <ErrorMessage message={errors.gross_weight?.message} />
      <Input
        type="number"
        label={t('tareWeight')}
        required={weightType === 'gross&tare'}
        error={!!errors.tare_weight}
        readOnly={weightType === 'net'}
        value={tareWeight}
        style={{
          background: weightType === 'net' ? '#F5F5F5' : 'inherit',
          color: weightType === 'net' ? '#E9E9E9' : 'inherit',
        }}
        {...register('tare_weight', {
          value: tareWeight,
          required: { value: weightType === 'gross&tare', message: t('fieldRequired') },
          onChange: handleTareWeightChange,
        })}
      />
      <ErrorMessage message={errors.tare_weight?.message} />
      <Input
        type="number"
        label={t('netWeight')}
        required={weightType === 'net'}
        error={!!errors.net_weight}
        readOnly={weightType === 'gross&tare'}
        value={weightType === 'gross&tare' ? netWeight : undefined}
        style={{
          background: weightType === 'gross&tare' ? '#F5F5F5' : 'inherit',
          color: weightType === 'gross&tare' ? '#C0C0C0' : 'inherit',
        }}
        {...register('net_weight', {
          value: netWeight,
          required: { value: weightType === 'net', message: t('fieldRequired') },
          min: { value: 0, message: t('fieldPositive') },
        })}
      />
      <ImageUpload
        instructions="imageInstructionsTruck"
        surveyType="truck-offloading"
        label={t('images')}
        {...register('pictures', { required: false, minLength: 1 })}
      />
      <ErrorMessage message={errors.pictures?.message} />
    </>
  );
}

export default TruckOffloadingForm;
